import api from '../../api';
import notifSystem from '../../notifSystem';
import store from '../../store';
import { closeDialog, setDialog } from '../dialog';
import { openPreviewPdf } from '../../utils/previewInNewTab';

export function uploadDoc(id, fileData) {
  return api.artisans.custom('artisanUpload').post({ id, ...fileData });
}

export function signalInformation(
  comment = '', cb = e => e, forceSignal = false
) {
  try {
    if (!comment.trim()) {
      return notifSystem.error('Erreur',
        'Merci de mettre un commentaire');
    }
    return api.artisans
      .custom('signal')
      .post({ comment, forceSignal })
      .then(() => {
        notifSystem.success('Message', 'Vous allez être rappelé sous 24h');
        cb();
      })
      .catch((err) => {
        if (err.message === 'Forbidden') {
          return notifSystem.error('Attention',
            'La limite de demande en ce jour a été atteinte.');
        }
        return notifSystem.error('Erreur', 'L\'action a pas pu être faite');
      });
  } catch (error) {
    notifSystem.error('Erreur', error.message || error);
    throw error;
  }
}

export const getSousMetiers = (categorie) =>
  api.metiersDevis
    .getAll({ query: JSON.stringify({ categories: { $in: [categorie] } }) })
    .then((e) => e.body().map((e) => e.data()));

export const openPreview = (id, type, title, withLoading = true) => {
  if (withLoading) {
    store.dispatch(
      setDialog({
        name: 'LoadingDialog',
        open: true,
        dialogProps: { title: 'Prévisualisation en cours' },
      })
    );
  }
  return api.interventions
    .custom(`download/${id}/${type}`)
    .get()
    .then((res) => {
      openPreviewPdf(`data:application/pdf;base64,${res.body().data()}`, title);
    })
    .catch((e) => {
      notifSystem.error(
        'Erreur system',
        e.message || e || 'Le fichier n\'a pas pu être généré.'
      );
    })
    .finally(() => withLoading && store.dispatch(closeDialog()));
};
